import { transactionsServices } from '@/services';

const {
  getTransactions,
  getTransactionDetails,
  exportPaymentLogs,
  approveOrDeclineTransaction,
  getFailedLookups,
} = transactionsServices;

export default {
  namespaced: true,
  actions: {
    async getLogs({ dispatch }, query) {
      try {
        const { data } = await getTransactions(
          query.type,
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || '',
          query?.transactionType || '',
          query?.fromAmount || '',
          query?.toAmount || '',
          query?.status || '',
          query?.startAt || '',
          query?.endAt || '',
          query?.sortBy || '',
          query?.orderBy || ''
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getSingleTransaction({ dispatch }, transactionId) {
      try {
        const { data } = await getTransactionDetails(transactionId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async exportLogs({ dispatch }, payload) {
      try {
        const { data } = await exportPaymentLogs(
          payload.EndAt,
          payload.Format,
          payload.StartAt,
          payload.Search,
          payload.logType
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async approveOrDeclineTxn({ dispatch }, payload) {
      try {
        const data = await approveOrDeclineTransaction(payload);
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message:
                payload.type === 'approve'
                  ? 'Transaction approved'
                  : 'Transaction declined',
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getFailedLookups({ dispatch }, query) {
      try {
        const { data } = await getFailedLookups(
          query?.page || 1,
          query?.pageSize || 50,
          query?.startAt || '',
          query?.endAt || ''
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};

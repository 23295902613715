import { store } from '@/store';
import {
  DASHBOARD_PERMISSIONS,
  COMPANY_PERMISSIONS,
  CUSTOMER_PERMISSIONS,
  LOAN_PERMISSIONS,
  INVESTMENT_PERMISSIONS,
  STAFF_PERMISSIONS,
  PARTNER_PERMISSIONS,
  CORPORATE_PERMISSIONS,
} from '@/constants/permissions';

export const hasPermission = (requiredPermissions, requireAll = true) => {
  // return true; // Just uncomment to test

  const userPermissions = store.getters['auth/currentUser']?.permissions || [];

  if (!requiredPermissions) return true;

  if (Array.isArray(requiredPermissions)) {
    return requireAll
      ? requiredPermissions.every((permission) => userPermissions.includes(permission)) // ALL required
      : requiredPermissions.some((permission) => userPermissions.includes(permission)); // ANY ONE required
  }

  return userPermissions.includes(requiredPermissions);
};

// Helper function to get all permissions
export const getAllPermissions = () => ({
  ...DASHBOARD_PERMISSIONS,
  ...COMPANY_PERMISSIONS,
  ...CUSTOMER_PERMISSIONS,
  ...LOAN_PERMISSIONS,
  ...INVESTMENT_PERMISSIONS,
  ...STAFF_PERMISSIONS,
  ...PARTNER_PERMISSIONS,
  ...CORPORATE_PERMISSIONS,
});

import apiClient from '@/clients/apiClient';

export const getFinancialOverview = (StartAt = '', EndAt = '') =>
  apiClient.get(`overview/finances?StartAt=${StartAt}&EndAt=${EndAt}`);

export const getLoanPerformanceOverview = () =>
  apiClient.get(`overview/loans/performance`);

export const getRiskAssetDistribution = () =>
  apiClient.get(`overview/loans/distribution`);

export const getLoanValuesByGroup = (grouping = '') =>
  apiClient.get(`overview/loans/values?grouping=${grouping}`);

export const getPastLoanCountByGroup = (grouping = '') =>
  apiClient.get(`overview/loans/past-due?grouping=${grouping}`);

export const getActionQueueData = () => apiClient.get(`action-queues`);

export const getGlobalDashboardMetrics = (StartAt = '', EndAt = '') =>
  apiClient.get(`overview/global-dashboard?StartAt=${StartAt}&EndAt=${EndAt}`);

// Dashboard Permissions
export const DASHBOARD_PERMISSIONS = {
  VIEW_DASHBOARD: 'view_dashboard',
  VIEW_DASHBOARD_REPORTS: 'view_dashboard_reports',
  VIEW_GLOBAL_DASHBOARD_REPORTS: 'view_global_dashboard_reports',
};

// Company Permissions
export const COMPANY_PERMISSIONS = {
  VIEW_ALL_COMPANIES: 'view_all_companies',
  VIEW_COMPANY_PROFILE: 'view_company_profile',
  CREATE_NEW_COMPANY: 'create_new_company',
  VIEW_COMPANY_HISTORY: 'view_company_history',
  VERIFY_COMPANY_KYB: 'verify_company_kyb',
  VIEW_COMPANY_DOCUMENTS: 'view_company_documents',
  ADD_COMPANY_DOCUMENT: 'add_company_document',
  RUN_CREDIT_CHECK: 'run_credit_check',
  VIEW_COMPANY_SETTINGS: 'view_company_settings',
  EDIT_COMPANY_SETTINGS: 'edit_company_settings',
  CHANGE_COMPANY_STATUS: 'change_company_status',
  VIEW_PENDING_APPROVAL_COMPANIES: 'view_pending_approval_companies',
  VIEW_PENDING_COMPANY_HISTORY: 'view_pending_company_history',
  VERIFY_PENDING_COMPANY: 'verify_pending_company',
  VIEW_PENDING_COMPANY_DOCUMENTS: 'view_pending_company_documents',
  ADD_PENDING_COMPANY_DOCUMENT: 'add_pending_company_document',
  RUN_PENDING_CREDIT_CHECK: 'run_pending_credit_check',
  VIEW_PENDING_COMPANY_SETTINGS: 'view_pending_company_settings',
  EDIT_PENDING_COMPANY_SETTINGS: 'edit_pending_company_settings',
  CHANGE_PENDING_COMPANY_STATUS: 'change_pending_company_status',
};

// Customer Permissions
export const CUSTOMER_PERMISSIONS = {
  VIEW_ALL_CUSTOMERS: 'view_all_customers',
  CREATE_CUSTOMER_PROFILE: 'create_customer_profile',
  VIEW_CUSTOMER_PROFILE: 'view_customer_profile',
  VIEW_CUSTOMER_DOCUMENT: 'view_customer_document',
  ADD_CUSTOMER_DOCUMENT: 'add_customer_document',
  VERIFY_CUSTOMER_KYC: 'verify_customer_kyc',
  VIEW_CUSTOMER_HISTORY: 'view_customer_history',
  EDIT_CUSTOMER_PROFILE: 'edit_customer_profile',
  RESET_CUSTOMER_PASSWORD: 'reset_customer_password',
  SUSPEND_CUSTOMER: 'suspend_customer',
  VIEW_PENDING_VERIFICATION_CUSTOMERS: 'view_pending_verification_customers',
  ADD_PENDING_CUSTOMER_DOCUMENT: 'add_pending_customer_document',
  VERIFY_PENDING_CUSTOMER_BVN: 'verify_pending_customer_bvn',
  VIEW_PENDING_CUSTOMER_HISTORY: 'view_pending_customer_history',
  EDIT_PENDING_CUSTOMER_PROFILE: 'edit_pending_customer_profile',
  RESET_PENDING_CUSTOMER_PASSWORD: 'reset_pending_customer_password',
  SUSPEND_PENDING_CUSTOMER: 'suspend_pending_customer',
};

// Loan Permissions
export const LOAN_PERMISSIONS = {
  VIEW_ALL_LOANS: 'view_all_loans',
  VIEW_LOAN_DETAILS: 'view_loan_details',
  BOOK_NEW_LOAN: 'book_new_loan',
  VIEW_LOAN_DOCUMENTS: 'view_loan_documents',
  UPLOAD_LOAN_DOCUMENT: 'upload_loan_document',
  APPROVE_LOAN: 'approve_loan',
  CHANGE_LOAN_SETTINGS: 'change_loan_settings',
  ADD_DISBURSEMENT_TRANSACTION: 'add_disbursement_transaction',
  ADD_REPAYMENT_TRANSACTION: 'add_repayment_transaction',
  AMEND_ACTIVE_LOAN: 'amend_active_loan',
  APPROVE_LOAN_AMENDMENT: 'approve_loan_amendment',
  EXTEND_LOAN_TENOR: 'extend_loan_tenor',
  APPROVE_LOAN_EXTENSION: 'approve_loan_extension',
  RESTRUCTURE_LOAN: 'restructure_loan',
  APPROVE_LOAN_RESTRUCTURE: 'approve_loan_restructure',
  GENERATE_LOAN_STATEMENT: 'generate_loan_statement',
  VIEW_LOANS_AWAITING_ACTION: 'view_loans_awaiting_action',
  VIEW_OTHER_LOANS: 'view_other_loans',
  CONVERT_LOAN: 'convert_loan',
  DECLINE_LOAN_REQUEST: 'decline_other_loan_request',
  ADD_DISBURSEMENT_TRANSACTION: 'add_disbursement_transaction',
  APPROVE_DISBURSEMENT: 'approve_disbursement',
  ACTIVATE_LOAN: 'activate_loan',
  ACCEPT_LOAN_FOR_PROCESSING: 'accept_loan_for_processing',
  UPDATE_LOAN_AS_CGO: 'update_loan_as_CGO',
  UPDATE_LOAN_AS_CRO: 'update_loan_as_CRO',
  UPDATE_LOAN_AS_CFO: 'update_loan_as_CFO',
  PROCESS_LOAN_COMMISION_AS_CFO: 'process_loan_commision_as_CFO',
  PROCESS_LOAN_COMMISION_AS_CGO: 'process_loan_commision_as_CGO',
};

// Investment Permissions
export const INVESTMENT_PERMISSIONS = {
  VIEW_ALL_INVESTMENTS: 'view_all_investments',
  VIEW_INVESTMENT_DETAILS: 'view_investment_details',
  PRELIQUIDATE_INVESTMENT: 'preliquidate_investment',
  GENERATE_INVESTMENT_CERTIFICATE: 'generate_investment_certificate',
  OPEN_INVESTMENT_NGN: 'open_investment_ngn',
  OPEN_INVESTMENT_USD: 'open_investment_usd',
  APPROVE_INVESTMENT: 'approve_investment',
  VIEW_INVESTMENT_PAYOUTS: 'view_investment_payouts',
  VIEW_INVESTMENT_TRANSACTIONS: 'view_investment_transactions',
  VIEW_INVESTMENT_DOCUMENTS: 'view_investment_documents',
  ADD_INVESTMENT_DOCUMENT: 'add_investment_document',
  ACTIVATE_INVESTMENT: 'activate_investment',
  ADD_INVESTMENT_DEPOSIT: 'add_investment_deposit',
};

// Payment Permissions
export const PAYMENT_PERMISSIONS = {
  VIEW_WEMA_LOGS: 'view_wema_logs',
  GENERATE_WEMA_STATEMENT: 'generate_wema_statement',
  ADD_WEMA_TRANSACTION_CREDIT: 'add_wema_transaction_credit',
  ADD_WEMA_TRANSACTION_DEBIT: 'add_wema_transaction_debit',
  VIEW_FLW_LOGS: 'view_flw_logs',
  GENERATE_FLW_STATEMENT: 'generate_flw_statement',
  ADD_FLW_TRANSACTION_CREDIT: 'add_flw_transaction_credit',
  ADD_FLW_TRANSACTION_DEBIT: 'add_flw_transaction_debit',
  APPROVE_AND_DECLINE_PAYMENTS: 'approve_and_decline_payments',
};

// Staff Account Permissions
export const STAFF_PERMISSIONS = {
  VIEW_ALL_STAFF_ACCOUNTS: 'view_all_staff_accounts',
  CREATE_STAFF_ACCOUNT: 'create_staff_account',
  VIEW_STAFF_ACCOUNT: 'view_staff_account',
  VIEW_STAFF_ACCOUNT_SETTING: 'view_staff_account_setting',
  EDIT_STAFF_PROFILE: 'edit_staff_profile',
  RESET_STAFF_PASSWORD: 'reset_staff_password',
  SUSPEND_ACCOUNT: 'suspend_account',
  UNSUSPEND_ACCOUNT: 'unsuspend_account',
  VIEW_ALL_ROLES: 'view_all_roles',
  VIEW_ROLE_DETAILS: 'view_role_details',
  ADD_ROLE: 'add_role',
  CHANGE_ROLE_SETTINGS: 'change_role_settings',
  DELETE_ROLE: 'delete_role',
};

// Partner Permissions
export const PARTNER_PERMISSIONS = {
  VIEW_PARTNERS: 'view_partners',
  CREATE_PARTNER: 'create_partner',
};

// Corporate Permissions
export const CORPORATE_PERMISSIONS = {
  VIEW_CORPORATE: 'view_corporate',
  CREATE_CORPORATE_PROFILE: 'create_corporate_profile',
  EDIT_CORPORATE_PROFILE: 'edit_corporate_profile',
  VERIFY_CORPORATE_PROFILE: 'verify_corporate_profile',
  MERGE_CORPORATE_PROFILE: 'merge_corporate_profile',
};

// Wallet Permissions
export const WALLET_PERMISSIONS = {
  APPROVE_WALLET_WITHDRAWAL: 'approve_wallet_withdrawal',
};

// Permission Groups
export const PERMISSION_GROUPS = {
  DASHBOARD: 'Dashboard',
  COMPANIES: 'Companies',
  CUSTOMERS: 'Customers',
  LOANS: 'Loans',
  INVESTMENTS: 'Investments',
  PAYMENTS: 'Payments',
  STAFF: 'Staff Accounts',
  PARTNERS: 'Partners',
  CORPORATES: 'Corporates',
  WALLET: 'Wallet',
};

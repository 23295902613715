import { getGlobalDashboardMetrics } from "@/services/dashboardServices";

export default {
    namespaced: true,
    state: () => ({
        globalDashboardData: null,
    }),
    getters: {
        globalDashboardData: (state) => state.globalDashboardData,
    },
    mutations: {
        SET_GLOBAL_DASHBOARD_DATA(state, data) {
            state.globalDashboardData = data;
        },
    },
    actions: {
        async getGlobalDashboardMetrics({ commit, dispatch }, query) {
            try {
                const { data } = await getGlobalDashboardMetrics(
                    query.StartAt || '',
                    query.EndAt || ''
                );
                commit('SET_GLOBAL_DASHBOARD_DATA', data);
                
                return data;
            } catch (error) {
                const { message, statusText } = error;
                dispatch(
                    'notifications/displayNotification',
                    { message: message ? message : statusText, type: 'danger' },
                    { root: true }
                );
                throw error;
            }
        },
    },
};
